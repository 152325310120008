metal-links {
  display: grid;
  grid-template-rows: 100px auto;
  width: 100vw;
  margin: 0 -50vw 0 -50vw;
}
metal-links p,
metal-links button,
metal-links a,
metal-links strong,
metal-links div {
  color: #FFFFFF;
  text-align: left;
}
metal-links a svg {
  height: 12px;
  width: 12px;
}
@media (max-width: 900px) {
  metal-links {
    background-color: var(--primary, #131f6b);
  }
  metal-links > .controller {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
  metal-links > .controller button {
    padding: calc(var(--gap) * 0.5);
    text-align: center;
    text-transform: uppercase;
    opacity: 0.6;
    font-size: 13px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  metal-links > .controller button svg {
    opacity: 0;
    color: #DF3035;
    height: 12px;
    width: 12px;
    position: absolute;
    bottom: 8px;
    left: calc(50% - 6px);
  }
  metal-links > .controller button.active {
    opacity: 1;
    background: rgba(0, 0, 0, 0.3);
  }
  metal-links > .controller button.active svg {
    opacity: 1;
  }
  metal-links > .controller button:first-of-type {
    border-right: 1px solid #969695;
  }
  metal-links > .controller button:last-of-type {
    border-left: 1px solid #969695;
  }
  metal-links > .content-container > div {
    display: none;
  }
  metal-links > .content-container > div .left {
    grid-template-columns: repeat(2, 50%);
    min-height: 200px;
    height: 100%;
    width: 100%;
    max-width: 100vw;
    opacity: 0;
    animation: fadeInTab 0.3s;
  }
  metal-links > .content-container > div .left > div {
    display: none;
  }
  metal-links > .content-container > div .left > a {
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: calc(var(--gap) * 0.5);
    aspect-ratio: 1/1;
  }
  metal-links > .content-container > div .left > a .image {
    height: 100%;
    width: 100%;
    position: absolute;
  }
  metal-links > .content-container > div .left > a .image::after {
    content: "";
    position: absolute;
    pointer-events: none;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
  }
  metal-links > .content-container > div .left > a p {
    font-size: 18px;
    font-weight: bold;
    z-index: 9;
    text-align: center;
  }
  metal-links > .content-container > div .right {
    display: none;
  }
  metal-links > .content-container > div.active {
    display: flex;
    width: 100%;
    height: 100%;
  }
  metal-links > .content-container > div.active .left {
    opacity: 1;
    display: grid;
  }
}
@media (min-width: 901px) {
  metal-links {
    grid-template-columns: auto 1fr;
    grid-template-rows: 1fr;
    width: 100%;
    min-height: 600px;
    margin: var(--spacer1) auto;
    left: unset;
    background-color: rgba(0, 0, 0, 0.3);
    padding: 0;
    border-radius: var(--border-radius, 6px);
  }
  metal-links > .controller {
    display: flex;
    writing-mode: vertical-rl;
    text-orientation: mixed;
    flex-direction: row-reverse;
    transform: rotate(180deg);
    height: 100%;
  }
  metal-links > .controller button {
    display: flex;
    flex-grow: 1;
    align-items: center;
    padding: var(--gap);
    border-left: 1px solid #969695;
    color: #969695;
    justify-content: center;
    transition: all var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, );
    text-transform: uppercase;
    font-size: 14px;
  }
  metal-links > .controller button svg {
    display: none;
  }
  metal-links > .controller button:first-of-type {
    border-bottom-right-radius: var(--border-radius, 6px);
    border-top: 1px solid #969695;
  }
  metal-links > .controller button:last-of-type {
    border-top-right-radius: var(--border-radius, 6px);
    border-bottom: 1px solid #969695;
  }
  metal-links > .controller button.active {
    color: #FFFFFF;
    border-left: 0px solid transparent;
  }
  metal-links > .controller button:hover {
    color: #FFFFFF;
  }
  metal-links > .content-container {
    position: relative;
  }
  metal-links > .content-container .content-link {
    display: none;
  }
  metal-links > .content-container > div {
    display: grid;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    grid-template-columns: 25% 75%;
    grid-template-rows: 100%;
    width: 100%;
    height: 100%;
    z-index: -3;
    animation: fadeOutTab 0.3s;
  }
  metal-links > .content-container > div.active {
    opacity: 1;
    z-index: 1;
    animation: fadeInTab 0.3s;
  }
  metal-links > .content-container > div > .left {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: calc(var(--gap) * 3);
  }
  metal-links > .content-container > div > .left div {
    transition: color var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, );
    margin-bottom: calc(var(--gap) * 0.5);
  }
  metal-links > .content-container > div > .left div strong {
    color: #DF3035;
    font-size: 18px;
  }
  metal-links > .content-container > div > .left div strong:hover {
    cursor: pointer;
  }
  metal-links > .content-container > div > .left div p {
    max-height: 0;
    opacity: 0;
    z-index: -9;
    pointer-events: none;
    font-size: 12px;
    transition: height var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, );
  }
  metal-links > .content-container > div > .left div.active p {
    max-height: 100%;
    opacity: 1;
  }
  metal-links > .content-container > div > .left div:hover strong, metal-links > .content-container > div > .left div.active strong {
    color: #FFFFFF;
  }
  metal-links > .content-container > div .right {
    position: relative;
  }
  metal-links > .content-container > div .right > .content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: -1;
    display: grid;
    grid-template-columns: 40% 60%;
    grid-template-rows: 100%;
    width: 100%;
    height: 100%;
  }
  metal-links > .content-container > div .right > .content .content-right {
    display: flex;
    flex-direction: column;
    padding: calc(var(--gap) * 3);
    color: white;
    gap: calc(var(--gap) * 2);
  }
  metal-links > .content-container > div .right > .content .content-right h4 {
    font-size: 14px;
  }
  metal-links > .content-container > div .right > .content .content-right h5 {
    color: #DF3035;
    font-size: 12px;
  }
  metal-links > .content-container > div .right > .content .content-right ul li {
    width: 100%;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  }
  metal-links > .content-container > div .right > .content .content-right ul li a {
    width: 100%;
    text-decoration: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: calc(var(--gap) * 0.5) 0;
    transition: background-color var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, );
    text-transform: uppercase;
  }
  metal-links > .content-container > div .right > .content .content-right ul li a:hover {
    background-color: rgba(255, 255, 255, 0.3);
  }
  metal-links > .content-container > div .right > .content .content-right ul li a svg {
    fill: #DF3035;
    color: #DF3035;
    stroke: #DF3035;
    height: 12px;
    width: 12px;
  }
  metal-links > .content-container > div .right > .content.active {
    opacity: 1;
    z-index: 9;
    animation: fadeInTab 0.3s;
  }
}

@keyframes fadeInTab {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeOutTab {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}