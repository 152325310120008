@use '_modules' as *;

metal-links {
  display: grid;
  grid-template-rows: 100px auto;
  width: 100vw;
  margin: 0 -50vw 0 -50vw;

  p,
  button,
  a,
  strong,
  div {
    color: $white;
    text-align: left;
  }

  a svg {
    height: 12px;
    width: 12px;
  }

  // Mobile view
  @include break-in(900) {
    background-color: $primary;

    > .controller {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      button {
        padding: $gap0;
        text-align: center;
        text-transform: uppercase;
        opacity: 0.6;
        font-size: 13px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        svg {
          opacity: 0;
          color: $red;
          height: 12px;
          width: 12px;
          position: absolute;
          bottom: 8px;
          left: calc(50% - 6px);
        }

        &.active {
          opacity: 1;
          background: rgba(0, 0, 0, 0.3);
          svg {
            opacity: 1;
          }
        }
        &:first-of-type {
          border-right: 1px solid $grey;
        }
        &:last-of-type {
          border-left: 1px solid $grey;
        }
      }
    }

    > .content-container {
      > div {
        display: none;
        .left {
          grid-template-columns: repeat(2, 50%);
          min-height: 200px;
          height: 100%;
          width: 100%;
          max-width: 100vw;
          opacity: 0;
          animation: fadeInTab 0.3s;

          > div {
            display: none;
          }
          > a {
            height: 100%;
            width: 100%;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: $gap0;
            aspect-ratio: 1 / 1;
            .image {
              height: 100%;
              width: 100%;
              position: absolute;
              &::after {
                content: '';
                position: absolute;
                pointer-events: none;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: rgba(0, 0, 0, 0.3);
              }
            }
            p {
              font-size: 18px;
              font-weight: bold;
              z-index: 9;
              text-align: center;
            }
          }
        }
        .right {
          display: none;
        }
        &.active {
          display: flex;
          width: 100%;
          height: 100%;
          .left {
            opacity: 1;
            display: grid;
          }
        }
      }
    }
  }

  // Tablet / Desktop view
  @include break-out(900) {
    grid-template-columns: auto 1fr;
    grid-template-rows: 1fr;
    width: 100%;
    min-height: 600px;
    margin: $spacer auto;
    left: unset;
    background-color: rgba(0, 0, 0, 0.3);
    padding: 0;
    border-radius: $border-radius;

    > .controller {
      display: flex;
      writing-mode: vertical-rl;
      text-orientation: mixed;
      flex-direction: row-reverse;
      transform: rotate(180deg);
      height: 100%;
      button {
        display: flex;
        flex-grow: 1;
        align-items: center;
        padding: $gap;
        border-left: 1px solid $grey;
        color: $grey;
        justify-content: center;
        transition: all $transition;
        text-transform: uppercase;
        font-size: 14px;
        svg {
          display: none;
        }

        &:first-of-type {
          border-bottom-right-radius: $border-radius;
          border-top: 1px solid $grey;
        }
        &:last-of-type {
          border-top-right-radius: $border-radius;
          border-bottom: 1px solid $grey;
        }

        &.active {
          color: $white;
          border-left: 0px solid transparent;
        }

        &:hover {
          color: $white;
        }
      }
    }

    > .content-container {
      position: relative;

      .content-link {
        display: none;
      }

      & > div {
        display: grid;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        grid-template-columns: 25% 75%;
        grid-template-rows: 100%;
        width: 100%;
        height: 100%;
        z-index: -3;
        animation: fadeOutTab 0.3s;

        &.active {
          opacity: 1;
          z-index: 1;
          animation: fadeInTab 0.3s;
        }

        > .left {
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: $gap3;

          div {
            transition: color $transition;
            margin-bottom: $gap0;

            strong {
              color: $red;
              font-size: 18px;
              &:hover {
                cursor: pointer;
              }
            }

            p {
              max-height: 0;
              opacity: 0;
              z-index: -9;
              pointer-events: none;
              font-size: 12px;
              transition: height $transition;
            }

            &.active {
              p {
                max-height: 100%;
                opacity: 1;
              }
            }

            &:hover,
            &.active {
              strong {
                color: $white;
              }
            }
          }
        }

        .right {
          position: relative;

          > .content {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            z-index: -1;
            display: grid;
            grid-template-columns: 40% 60%;
            grid-template-rows: 100%;
            width: 100%;
            height: 100%;

            .content-right {
              display: flex;
              flex-direction: column;
              padding: $gap3;
              color: white;
              gap: $gap2;

              h4 {
                font-size: 14px;
              }

              h5 {
                color: $red;
                font-size: 12px;
              }
              ul {
                li {
                  width: 100%;
                  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
                  a {
                    width: 100%;
                    text-decoration: none;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    padding: $gap0 0;
                    transition: background-color $transition;
                    text-transform: uppercase;
                    &:hover {
                      background-color: rgba(255, 255, 255, 0.3);
                    }
                    svg {
                      fill: $red;
                      color: $red;
                      stroke: $red;
                      height: 12px;
                      width: 12px;
                    }
                  }
                }
              }
            }

            &.active {
              opacity: 1;
              z-index: 9;
              animation: fadeInTab 0.3s;
            }
          }
        }
      }
    }
  }
}

@keyframes fadeInTab {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOutTab {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
